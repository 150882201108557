<template>
  <div
    v-if="isMobile()"
    style="height: 100vh; width: 100vw"
    class="flex flex-col w-full"
  >
    <mobile-top-bar />
    <router-view></router-view>
    <mobile-bottom-bar style="height: 8vh" class="fixed bottom-0 z-50" />
  </div>
  <div v-if="!isMobile()" class="flex">
    <desktop-side-bar />
    <div class="md:w-full md:right-0 relative">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/global/services/mixins";
import { defineAsyncComponent } from "vue";

export default {
  name: "app-layout",
  components: {
    MobileTopBar: defineAsyncComponent(() =>
      import("@/global/mobile-top-bar")
    ),
    MobileBottomBar: defineAsyncComponent(() =>
      import("@/global/mobile-bottom-bar")
    ),
    DesktopSideBar: defineAsyncComponent(() =>
      import("@/global/desktop-side-bar")
    )
  },
  props: {},
  setup() {
    return { isMobile };
  }
};
</script>
